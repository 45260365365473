.safety_container{
    background: #00B37C;
}

.safety_container .chat_Across_devices{
    border-radius: 0px 60% 0px 0px;
    padding: 10% 5% 10% 8%;
    /* min-height: 100vh; */
    background: #FFB11B;
}
.hindibtn{
    background: #8A25EF;
    padding: 2% 5%;
    border-radius: 30px;
    border: 0px;
    width: 31%;
}
.btn_F23D63{
    background: #F23D63;
}
.btn_00B37C{
    background: #00B37C;
}
.btn_02AFF7{
    background:#02AFF7;
}
.safety_content ul li{
    color: #fff;
    line-height: 40px;
}
.safety_container .chat_Across_devices1::before{
    content: '';
    width: 100%;
    height: 60px;
    background-image: url('../../Asstes/images/new/Vector\ 4.png');
    position: absolute;
    top: 10%;
    right: -50%;
    background-size: 20%;
    background-repeat: no-repeat;
    z-index: 1;
}
.safety_container .chat_Across_devices1::after{
    content: '';
    background-image: none;
}
.safety_container .chat_Across_devices::before{
    content: '';
    width: 100%;
    height: 60px;
    background-image: url('../../Asstes/images/new/Vector\ 10.png');
    position: absolute;
    top: 85%;
    right: -25%;
    background-size: 20%;
    background-repeat: no-repeat;
    z-index: 1;
}
@media screen and (max-width:480px) {
    .hindibtn{
        width: 30%;
        color:#fff;
    }
    .safety_container .chat_Across_devices::before{
        top: 25%;
        right: -50%;
        background-size: 25%;    
    }
    .safety_container .chat_content div{
        justify-content: flex-start;
        padding: 0px !important;
    }
    div.safety_img, div.safety_img img{
        margin-top: 0px !important;
    }
    .safety_container .chat_Across_devices1::before{
        display: none;
    }
    .chat_Across_devices h3.mt-5{
        margin-top: 0px !important;
    }
    .chat_fourth_bg.mt-5{
        margin-top: 20px !important;
    }
    #root > section.chatAcross_contaner.safety_container > div > div > div.chat_Across_devices1.d-flex.align-items-center > div > div > div.safety_content > h3 > br{
        display: none;
    }
}
