.card_instal p{
    font-size: 14px;
    margin-bottom: 0px;
    font-weight: 600;
    
}   

.howToInstall_section{
  background: #F5F5F5;
  padding-bottom: 50px;
  background: url('../../Asstes/images/new/Group\ 72.png') left top no-repeat, url('../../Asstes/images/new/Group\ 859.png') right top no-repeat;
  background-size: 7% 100%;
  position: relative;
  overflow: hidden;
}
.howToInstall_section::before{
    content: '';
    width: 100%;
    height: 25vh;
    background-image: url('../../Asstes/images/new/Group\ 869.png');
    background-size: 9%;
    background-repeat: no-repeat;
    position: absolute;
    top: 220px;
    left: 223px;
    transform: rotate(19deg);
}


@media screen and (max-width:480px) {
    .howToInstall_section{
        background-size: 30% 100%;
    }
    div.HowToInstal_container{
        justify-content: start !important;
        row-gap: 30px;
    }
    div.head_contect{
        padding: 20px 0px !important;
    }
    
    .howToInstall_section::before{
        top: 195px;
        left: 130px;
        background-size: 25%;
    }
}