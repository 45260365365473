.call_container{
    background: #FFB11B;
    position: relative;
    overflow: hidden;
}
.call_container_bg{
    background:#F5F5F5;
    padding: 5% 0%;

}
.call_container::before{
    content: '';
    width: 100%;
    height: 220px;
    background-image: url('../../Asstes/images/new/Mask\ group.png');
    position: absolute;
    top: -0%;
    right: 0%;
    background-size: 15%;
    background-repeat: no-repeat;
    z-index: 1;
}
.call_container::after{
    content: '';
    width: 100%;
    height: 320px;
    background-image: url('../../Asstes/images/new/Mask\ group1.png');
    position: absolute;
    bottom: 0%;
    left: 85%;
    background-size: 15%;
    background-repeat: no-repeat;
    z-index: 1;
}
.call_services::before{
    content: '';
    width: 100%;
    height: 135px;
    background-image: url('../../Asstes/images/new/Group.png');
    position: absolute;
    top: 2%;
    left: 33%;
    background-size: 25%;
    background-repeat: no-repeat;
    z-index: 1;
}
.call_container2, .call_container1{
    width: 50%;
    padding: 20px 0px;
}

.w-60{
    width: 65% !important;
}
.calls_warrper .chat_Across_devices1{
    display: flex;
    justify-content: end;
}
.chat_content .index-module__container--WJ2DC .index-module__iconLayout--tuQHv{
    width: calc(100% - 90.475px);
}
.calls_warrper .chat_Across_devices1 .chat_content .index-module__container--WJ2DC .index-module__iconLayout--tuQHv {
    position: relative;
    width: calc(100%);
    margin-right: 20px;
}

@media screen and (max-width:480px) {
    .call_container2, .call_container2 .chat_content, .call_container1{
        width: 100% !important;
        text-align: center;
    }
    .call_container2 .chat_content div,
    .call_container1 .chat_content div{
        margin-top: 0px !important;
        padding: 5px !important;
        width: 100% !important;
    }
    .call_container2 .chat_content h3{
        font-size: 35px !important;
    }
    .call_container .index-module__container--WJ2DC .index-module__iconLayout--tuQHv img{
        width: 100%;
    }
    .more_fun_container{
        flex-direction: column-reverse;
    }
    .call_container .index-module__iconLayout--tuQHvg img, .call_container2 .index-module__iconLayout--tuQHvw img{
        width: 40%;
    }
    .chat_content.mt-5{
        margin-top: 0px;
    }
    .call_container2, .call_container1{
        padding: 0px ;
    }
    .more_fun_container .call_container2 .index-module__container--WJ2DC, .call_container_bg .index-module__container--WJ2DC{
        margin-top: 20px !important;
    }
    .more_fun_container .call_container2 .index-module__container--WJ2DC .index-module__iconLayout--tuQHvw.mt-5,
    .more_fun_container .call_container2 .index-module__container--WJ2DC .index-module__iconLayout--tuQHvw img.mt-5 {
        margin-top: 10px !important;
    }
}