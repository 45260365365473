.chatAcross_contaner {
    /* background: linear-gradient(45deg, #8A25EF, #FB4FFF); */
    background: #8A25EF;
    padding: 0px 0px 0px 0px;
    overflow: hidden;
}

.chat_Container {
    display: flex;
    /* align-items: center; */
    width: 100%;
    /* min-height:100vh; */
}

.lets_btn {
    background: #fff !important;
    border-radius: 30px !important;
    width: 100%;
}

.chat_Across_devices {
    background: #FB4FFF;
    border-radius: 60% 0px 0px 0px;
}


.chat_Across_devices1,
.chat_Across_devices {
    width: 50%;
}

.chat_content .index-module__container--WJ2DC .index-module__iconLayout--tuQHv {
    position: relative;
    width: calc(75% - 170.475px);
}

.chat_Across_devices1 {
    position: relative;
}

.chat_Across_devices1::before {
    content: '';
    width: 100%;
    height: 60px;
    background-image: url('../../Asstes/images/new/Vector\ 3.png');
    position: absolute;
    top: 40%;
    right: -82%;
    background-size: 20%;
    background-repeat: no-repeat;
    z-index: 1;
}

.chat_Across_devices1::after {
    content: '';
    width: 100%;
    height: 200px;
    background-image: url('../../Asstes/images/new/Group.png');
    position: absolute;
    top: 56%;
    right: -71%;
    background-size: 55%;
    background-repeat: no-repeat;
    z-index: 1;
}

.chat_content p {
    font-size: 25px;
    color: #fff;
}

.chat_content h1 {
    font-size: 45px !important;
    font-weight: bolder;
    font-style: italic;
    color: #fff;
}

.chat_Across_devices {
    padding-right: 5%;
}

.chat_Across_devices1 {
    padding-left: 5%;
}

.chat_first_bg {
    background: url('../../Asstes/images/new/Group\ 877.png') center center no-repeat;
    background-repeat: no-repeat;
    background-size: 70%;
    min-height: 440px;
    position: relative;
    transform: translate(11%, 24%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat_first_bg::before {
    content: '';
    width: 100%;
    height: 200px;
    background-image: url('../../Asstes/images/new/Group\ 878.png');
    position: absolute;
    top: 15px;
    right: -13%;
    background-size: 24%;
    background-repeat: no-repeat;
}

.chat_first_bg::after {
    content: '';
    width: 100%;
    height: 120px;
    background-image: url('../../Asstes/images/new/Group\ 879.png');
    position: absolute;
    top: 15px;
    right: -60%;
    background-size: 24%;
    background-repeat: no-repeat;
}

.chat_second_bg {
    position: absolute;
    bottom: 15%;
    left: 30%;
}

.chat_second_bg::before {
    content: '';
    width: 100%;
    height: 40px;
    background-image: url('../../Asstes/images/new/Group\ 883.png');
    position: absolute;
    top: -65px;
    right: 40%;
    background-size: 65%;
    background-repeat: no-repeat;
}

.chat_second_bg::after {
    content: '';
    width: 100%;
    height: 50px;
    background-image: url('../../Asstes/images/new/Group\ 885.png');
    position: absolute;
    top: 80px;
    right: -110%;
    background-size: 100%;
    background-repeat: no-repeat;
}

.chat_threed_bg {
    position: absolute;
    bottom: 20%;
    right: 13%;
}

@media screen and (max-width:480px) {
    .chat_Container {
        flex-wrap: wrap;
    }

    .chat_Across_devices1,
    .chat_Across_devices {
        width: 100%;
    }

    .chat_content div {
        padding: 20px !important;
    }
    .chat_first_bg{
        transform: translate(8%, 2%);
        background-size: 80%;
    }
    .chat_first_bg img{
        width: 110px;
        height: 110px;
    }
    .chat_first_bg::after{
        top: 60px;
        background-size: 20%;
    }
    .chat_first_bg::before {
        top: 45px;
        right: -15%;
        background-size: 24%;
    }
    .chat_second_bg::before{
        top: -80px;
        right: 83%;
        background-size: 100%;
    }
    .chat_second_bg::after{
        top: 33px;
        right: -145%;
    }
    .chat_threed_bg{
        bottom: 25%;
        right: 3%;
    }
    .chat_second_bg img{
        width: 65px; 
    }
    .chat_threed_bg img{
        width: 110px;
    }
    .chat_content div.mt-5{
        margin-top: 0px !important;
    }
    
}