.About_section_container{
    background: #F5F5F5;
    padding: 20px 0px;
}
.text-justify{
    text-align: justify !important;
}
.About_container1{
    margin-top: 100px;
}
.about_card{
    background: #FFF7E6;
    border-radius: 20px;
    width: 100%;
    text-align: justify;
    box-shadow: 0px 0px 10px 2px #eee;
}
#root > section.About_section_container > div > div.gallery.About_container1 > div:nth-child(2) > div{
    background: #FFE3E3;
}
#root > section.About_section_container > div > div.gallery.About_container1 > div.gallery__item.gallery__item--v-2 > div{
    background: #EEFFFA;
}
#root > section.About_section_container > div > div.gallery.About_container1 > div:nth-child(4) > div{
    background: #F0F7FF;
}
#root > section.About_section_container > div > div.gallery.About_container1 > div:nth-child(5) > div{
    background: #FEE0FF;
}
.about_card img{
    right: 20px;
    bottom: 10px !important;
}

  
  /* Gallery  */
  
  .gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* grid-template-rows: repeat(3, 1fr); */
    grid-row-gap: 20px;
    grid-column-gap: 20px;
  }
  
  .gallery__item--v-2 {
    grid-row-end: span 2;
  }
  
  .gallery__item--v-3 {
    grid-row-end: span 3;
  }
  

  @media screen and (max-width: 480px) {
    .about_container .about_img,.about_container .about_content{
        width: 100% !important;
    }
    .about_content{
        text-align: center;
    }
    .gallery {
        grid-template-columns: repeat(1, 1fr);
    }
    .About_container1{
        margin-top: 50px;
    }
  }
