.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.contact_details_container {
  padding-left: 20px;
}

.top_header_container {
  border-bottom: 1px solid var(--border_color);
  background: #f8d458;
}

.social_details_container ul,
.menu_inner_container {
  list-style: none;
}

.sub_menu_container {
  border-top: 1px solid #eee;
}

.menu_inner_container .menu_link {
  cursor: pointer;
  position: relative;
  font-size: 16px;
}

.whatsApp_btn {
  right: 10px;
  bottom: 20px;
  z-index: 11;
  background: #25D366 !important;
  color: #fff;
  /* background-image: url('./Assets/images/banner5.jpg'); */
}

.whatsApp_btn .fa-whatsapp {
  font-size: 35px;
}

.menu_inner_container .menu_link::before {
  content: '';
  width: 1px;
  height: 1px;
  background: var(--main_color);
  position: absolute;
  bottom: -8px;
  transition: 0.3s;
  opacity: 0;
}

.menu_inner_container .menu_link:hover::before {
  height: 2px;
  width: 100%;
  transition: 0.3s;
  opacity: 1;
}

.icon_box {
  font-size: 12px
}

.menu_inner_container .menu_link:last-child::before {
  display: none;
}

.sub-menu_list {
  width: 250px !important;
  padding: 10px 15px;
  position: absolute;
  display: none;
  top: 25px;
  z-index: 11;
  background: #eee;
}

.sub-menu_cant {
  height: 60px;
}

.sub-menu_cant:hover {
  background: #eee;
}

.sub-menu_list1 {
  top: 60px;
  left: 0px;
}

.sub-menu_list li:hover {
  color: #999;
}

.sub-menu_list li {
  padding: 5px 0px;
}

.menu_inner_container .menu_link:hover .sub-menu_list {
  display: block;
}

.sub-menu_cant {
  font-size: 12px;
  padding: 10px 20px;
  display: flex;
  column-gap: 7px;
  align-items: center;
}
.desk_top{
  display: block;
}
.mob_480{
  display: none;
}

.mobHeader span {
  width: 100%;
  height: 2px;
  background-color: rgb(134 130 130);
}
.mobHeader span:first-child {
  margin-top: 0px;
}
.modal-content {
  width: 100% !important;
  height: 101vh;
  margin-left: 0px;
  margin-top: -1px;
  border-radius: 0px !important;
}
.manu {
  list-style: none;
  padding: 0px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-column-gap: 50px;
  column-gap: 50px;
  flex-wrap: wrap;
}
.mobmanu .manu li {
  color: #000 !important;
}

@media screen and (max-width: 767px) {
  .modal-dialog {
    margin: 0px !important;
  }
  .mobmanu ul li {
    display: block !important;
    font-size: 16px;
    line-height: 30px;
    padding: 0px 15px;
    /* margin-top: 10px; */
    margin-right: 0px !important;
}
.mobmanu ul li img {
  margin-right: 20px;
}
.mobHeader {
  border: 1px solid #ddd;
  padding: 5px;
  width: 45px;
  height: 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
}
}

@media screen and (max-width: 480px) {
  .manu {
    justify-content: flex-start;
  }
  ul.desk_top{
    display: none !important;
  }
  .mob_480{
    display: block;
  }
  .manu a {
    width: 100%;
    border: 1px solid #eee;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    padding: 10px 0px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 2px #eee;
    justify-content: space-between;
    padding-right: 15px;
    text-decoration: auto;
}

}