.footer_section {
    background: #8A25EF;
    padding: 30px 0px;
}

.Quick_Links ul li {
    line-height: 40px;
    transition: .3s;
}

.Quick_Links ul li a {
    color: #fff;
    text-decoration: none;
}

.Quick_Links ul li a:hover {
    color: #999;
    transition: .3s;
}

@media screen and (max-width: 480px) {

    .letsChat_about_container,
    .Quick_Links, .letsChat_logo {
        width: 100% !important;
        text-align: center;
    }
    .letsChat_about_container h3{
        padding: 0px 30px;
    }
    .letsChat_logo img.appstore, .Quick_Links img.google, .Quick_Links p {
        display: none;
    }
    .Quick_Links{
        margin-top: 10px;
    }
    .Quick_Links ul{
        margin-top: 0px !important;
    }
    .letsChat_logo .logo {
        width: 70% !important;
    }
}