.first_slide_container{
    background:linear-gradient(45deg, #FB4FFF, #8A25EF);
    padding: 30px 0px 0px 0px;
    min-height: 83vh;
    display: flex;
    align-items: flex-end;
    background-repeat: no-repeat;
    column-gap: 5;
    position: relative;

}
.first_slide_container::before{
    content: '';
    width: 100%;
    height: 90vh;
    background-image: url('../../Asstes/images/element-3.png');
    background-size: 40%;
    background-repeat: no-repeat;
    position: absolute;
    top: -50px;
    left: -100px;
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}
.advanced_banner_option {
    margin-bottom: 20px;
}
.advanced_banner_option span.top_blue_banner_heading {
    color: #fff;
    font-family: Inter;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 1;
    display: inline-block;
}
.advanced_banner_option p.light_blue_heading {
    color: #fff;
    font-family: Inter;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    margin: 0;
}
.advanced_banner_option span.bottom_blue_banner {
    color: #fff;
    font-family: Inter;
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: 42px;
    display: inline-block;
}
.advanced_banner_option span.bottom_blue_banner strong {
    font-weight: 900;
}
.card-text{
    font-size: 14px;
    color: #fff;
    margin-bottom: 0px;
}
.sms_section{
    width: 70%;
    /* padding: 0px 150px 0px 0px; */
    
}
.digit {
    display: flex;
    align-items: center;
    width: auto;
    background: #fff;
    overflow: hidden;
    border-radius: 10px;
}

.digit:before {
    content: "+91";
    position: relative;
    left: 7px;
    color: #000;
}
input.phone-number {
    background-color: #fff;
    padding: 12px 5px 8px 30px;
    color: #383838;
    font-size: 14px;
    width: calc(100% - 150px);
    margin-left: -24px;
    border: 0px;
}
input.phone-number:focus-visible{
    outline: none;
}
button.get-app-btn {
    cursor: pointer;
    margin-left: 3px;
    border-radius:0px 0px 0px;
    border: none;
    background-color: #8A25EF;
    text-align: center;
    color: #fff;
    padding: 12px 15px;
    font-size: 12px;
    width: 40%;
}
label.invalid {
    margin-top: 1px;
    color: red;
    font-size: 12px !important;
    display: none;
}
p.qr-section {
    display: flex;
    color: #003e86;
    align-items: center;
}
p.qr-section>span {
    width: 63%;
    font-size: 125%;
    color: #fff;
}
/* .img_container{
    background-image: url('../../Asstes/images/new/Girls\ 1.png');
    background-repeat: no-repeat;
} */
.instant_withdraw>div {
    display: flex;
    font-size: 10px;
    color: #fff;
}
.instant_withdraw>div .col {
    display: flex;
    align-items: center;
    margin-right: 20px;
}
.instant_withdraw > div .col img {
    margin-right: 8px;
}
.index-module__container--WJ2DC {
    -moz-box-align: center;
    align-items: center;
    display: -moz-box;
    display: flex;
    width: 100%;
}
.index-module__container--WJ2DC .index-module__iconLayout--tuQHv {
    position: relative;
    width: -moz-calc(50% - 170.475px);
    width: calc(50% - 170.475px);
}
.index-module__container--WJ2DC .index-module__iconLayout--tuQHv:hover:before {
    background: url('../../Asstes//images/ar.webp') 50% no-repeat;
    background-size: 100% 100%;
    border-radius: 1.1111111111111112vw;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    content: "";
    height: 11.11111111111111vw;
    left: 50%;
    padding: 2.2222222222222223vw;
    position: absolute;
    top: -1.3888888888888888vw;
    -moz-transform: translate(-50%,-93%);
    transform: translate(-50%,-93%);
    width: 11.11111111111111vw;
}
.index-module__container--WJ2DC .index-module__iconLayout--tuQHv:hover:after {
    border: 0.8333333333333334vw solid transparent;
    border-top-color: #fff;
    content: "";
    height: 0;
    left: 50%;
    position: absolute;
    top: 0.2222222222222222vw;
    -moz-transform: translate(-50%,-100%);
    transform: translate(-50%,-100%);
    width: 0;
}
.img_container{
    position: relative;
}
.img_container::before{
    content: '';
    width: 100%;
    height: 25vh;
    background-image: url('../../Asstes/images/new/Group\ 866.png');
    background-size: 63%;
    background-repeat: no-repeat;
    position: absolute;
    top: -12px;
    left: -210px;
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}
.img_container::after{
    content: '';
    width: 100%;
    height: 25vh;
    background-image: url('../../Asstes/images/new/Group\ 867.png');
    background-size: 25%;
    background-repeat: no-repeat;
    position: absolute;
    top: 25px;
    right: -315px;
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}
.img_container .img1container::before{
    content: '';
    width: 100%;
    height: 25vh;
    background-image: url('../../Asstes/images/new/Group\ 869.png');
    background-size: 25%;
    background-repeat: no-repeat;
    position: absolute;
    top: 30%;
    left: -70px;
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}
.img_container .img1container::after{
    content: '';
    width: 100%;
    height: 25vh;
    background-image: url('../../Asstes/images/new/Group\ 868.png');
    background-size: 10%;
    background-repeat: no-repeat;
    position: absolute;
    top: 79%;
    left: 35px;
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 5px;
}



@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 15px); }
    100%   { transform: translate(0, -0px); }    
}

@media screen and (max-width:480px) {
    .first_slide_container{
        min-height: 84% !important;
    }
    div.app_details,.sms_section{
        width: 100% !important;
    }
    .img_container{
        display: none;
    }
    .index-module__container--WJ2DC .index-module__iconLayout--tuQHv {
        position: relative;
        width: calc(97% - 170.475px);
    }
    .first_slide_container::before{
       background-size: 95%; 
    }
}