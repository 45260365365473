.accordion-button:focus{
    box-shadow:none !important;
}
.accordion-button:hover{
    background: transparent !important;
    z-index: 0 !important;
}
.accordion-button{
    border-bottom: 1px solid #ddd !important;
    background: #F5F5F5 !important;
    font-weight: 700 !important;
    padding: 25px !important;
}
.accordion-button:not(.collapsed){
    background: transparent !important; 
}
.accordion-item{
    border: 1px !important;
}
.FAQs_container{
    background: #F5F5F5;
    padding-top: 100px;
    padding-bottom: 50px;
}
.FAQs_Banner h3{
    text-align: left !important;
    margin-top: 30px;
}
@media screen and (max-width: 480px) {
    .FAQs_container{
        padding-top: 10px;
    }
    .FAQs_wraper .FAQs_Banner ,.FAQs_wraper .FAQs_Qua{
        width: 100% !important;
    }
    #root > section.FAQs_container > div > div > div.w-50.FAQs_Banner.text-center > img.p-5{
        display: none;
    }
    .FAQs_wraper .FAQs_Banner h3{
        text-align: center !important;
    }

}