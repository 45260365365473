body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.main-container {
  background-color: #fff;
  background-image: radial-gradient(at 0 100%,#bfdbfe 0,transparent 40%),radial-gradient(at 100% 100%,#fbcfe8 0,transparent 34%),radial-gradient(at 44% 34%,#bfdbfe 0,transparent 39%),radial-gradient(at 62% 35%,#fbcfe8 0,transparent 27%);
}
.support_wrapper{
  border-radius: 10px;
  box-shadow: 0px 0px 10px 2px #eee;
}
.support_form{
  padding: 30px 20px;
}
.support_form .form-group{
  margin-bottom: 20px;
  width: 100% !important;
}
.btnSubmit{
  width: 100%;
  border: 1px solid #00B37C;
  background: #00B37C;
  padding: 10px;
  border-radius: 10px;
}

@media screen and (max-width:480px) {
    div.support_wrapper,
    div.support_img,
    div.support_form{
      width: 100% !important;
    }
    div.desk_top{
      display: none;
  }
}



